import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
//  import { navigate } from 'gatsby';
// import { GatsbyImage } from 'gatsby-plugin-image';

import { DownloadButtonData } from '../../../types/types';
import { ANALYTICS_CONSTANTS } from '../../../constants';
import AnalyticsContext from '../../../context/analytics/analyticsContext';
import PageContext from '../../../context/page/pageContext';
import { redirectToGCLID, redirectCheckNotifiation, redirectCheckNotifiationWithapkLocal, extraEventForGA, redirectToVideoSectionInDesktop, apkPromiseFunction, whiteScreenLStenClick } from '../../../../utils/redirectCheck';
import { FbPixelEvent_Lead } from "../../../../utils/FbPixel/fbPixel";
import { debounce } from '../../../../utils/debounce';
import MobileNumberForm from '../MobileNoHandlingBlocks/MobileNumberForm';

const DownloadButton = (props: DownloadButtonData) => {
	const analyticsPrefix = useContext(AnalyticsContext);
	const { pageRelatedTo, apkPromise, gclidValue, gatsbyDownloadBtnEvent, andriodLinkRef, popupThankyouRef, setPopupThankyouRef, fallbackCase } = useContext(PageContext);
	const [lastClickTime, setLastClickTime] = useState(0);
  const [firstClickIgnored, setFirstClickIgnored] = useState(false);
	const[buttonClickedInThreshold, setButtonClickedInThreshold]= useState(false);

	const handleDoubleClickCheck = () => {
		let isUserComeAgain = localStorage.getItem('userComeAgain');
		if (isUserComeAgain === '' || isUserComeAgain === null) {
			localStorage.setItem('userComeAgain', 'true');
		}
		if(isUserComeAgain === 'true') {
			setButtonClickedInThreshold(!buttonClickedInThreshold);
			const bodyRef = document.querySelector("body");
			bodyRef?.classList.add("body-overflow-hidden");
		}
	}

	const handleDownload = (e) => {
		let dataLayer = window.dataLayer || [];
		dataLayer.push({
			event: ANALYTICS_CONSTANTS.EVENT,
			category: analyticsPrefix + ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.CATEGORY + ` ${pageRelatedTo}`,
			action: analyticsPrefix + ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.ACTION,
			label: analyticsPrefix + ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.LABEL
		});


		if (pageRelatedTo == 'ls1page') {
			redirectToGCLID(apkPromise, gclidValue);
			extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
		} else if (
			pageRelatedTo === 'ls3page' ||
			pageRelatedTo === 'ls5page' ||
			pageRelatedTo === 'lspage' ||
			pageRelatedTo === 'ls15page' ||
			pageRelatedTo === 'ls2page' ||
			pageRelatedTo === 'snlpage' ||
			pageRelatedTo === 'carrompage' ||
			pageRelatedTo === 'ls4hindipage' ||
			pageRelatedTo === 'lsbengalipage' ||
			pageRelatedTo === 'lsmarathipage' ||
			pageRelatedTo === 'phonepepage' ||
			pageRelatedTo === 'zupeenewyearcampaign' ||
			pageRelatedTo === 'lsremarketingpage'  ||
			pageRelatedTo === 'lsgoogle' ||
			pageRelatedTo === 'ls6page' ||
			pageRelatedTo === 'tcmpage' ||
			pageRelatedTo === 'gujaratinols' ||
			pageRelatedTo === 'marathinols' ||
			//pageRelatedTo === 'ls10page' ||
			pageRelatedTo === 'ls11page' ||
			pageRelatedTo === 'ls16page'
		) {
			redirectToVideoSectionInDesktop();
			redirectCheckNotifiation(apkPromise);
			extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent); 

		} else if (pageRelatedTo === 'ls10page'){
			whiteScreenLStenClick();
			redirectToVideoSectionInDesktop();
			redirectCheckNotifiation(apkPromise);
			extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
		}
		
		else if (pageRelatedTo === 'ls4page') {
			const urlParams = new URLSearchParams(window.location.search);
			const gpsadid = urlParams.get('gps_adid');

			if (gpsadid == null || gpsadid == '') {
				redirectCheckNotifiation(apkPromise);
				extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
				redirectToVideoSectionInDesktop();
				redirectCheckNotifiation(apkPromise);

			} else {
				const redirectURLGAID = 'https://zupee.com/downloads/ls4/';
				extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
				const redtURL = 'https://app.adjust.net.in/kbw6mm4?gps_adid=' + gpsadid + '&matching=none&engagement_type=fallback_click';
				window.open(redtURL);
				redirectToVideoSectionInDesktop();
				redirectCheckNotifiation(apkPromise);
			}
		} else if (
			pageRelatedTo === "lsfbpage" ||
			pageRelatedTo === 'goldsilverpage' || 
			pageRelatedTo === "kannadanols" ||
			pageRelatedTo === "bengalinols" ||
			pageRelatedTo === "homepagehindi" ||
			pageRelatedTo === "lsbhojpuripage" ||
			pageRelatedTo === "lsgujaratipage" ||
			pageRelatedTo === 'playstore' || 
			pageRelatedTo === 'lskannadapage' ||
			pageRelatedTo === 'lstamilpage' ||
			pageRelatedTo === 'ls14page'||
			pageRelatedTo === 'homepage'
		) {
			
			redirectToVideoSectionInDesktop();
			redirectCheckNotifiation(apkPromise);
			extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
			FbPixelEvent_Lead();
		
		} else if (pageRelatedTo === "ls7page") {

			setTimeout(() => {
				apkPromiseFunction(apkPromise, "_self");
				extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
			}, 500);
			// popup thank you set with true on buttonclick
			setTimeout(function () { setPopupThankyouRef(!popupThankyouRef); }, 5000);
		}else if (pageRelatedTo === "ls13page") {
			const urlParams = new URLSearchParams(window.location.search);
			const gpsadid = urlParams.get('gps_adid');
			if (gpsadid == null || gpsadid == '') {
				redirectCheckNotifiation(apkPromise);
				extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
				redirectToVideoSectionInDesktop();
				redirectCheckNotifiation(apkPromise);
			} else {
				setTimeout(() => {
					const redtURL = 'https://app.adjust.net.in/kbw6mm4?gps_adid=' + gpsadid + '&matching=none&engagement_type=fallback_click&redirect=https://www.zupee.com/downloads/thankyou/';
					window.open(redtURL, "_self");
				}, 500);
				setTimeout(()=>{
					extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
					redirectToVideoSectionInDesktop();
					redirectCheckNotifiation(apkPromise);
				}, 100);
			}
		}else if(pageRelatedTo === 'ls8page'){
			//localStorage.setItem('apkURL',andriodLinkRef);
			FbPixelEvent_Lead();
			redirectCheckNotifiationWithapkLocal(apkPromise);
			extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
	} else {
			redirectCheckNotifiation(apkPromise);
			extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
		}
	};

	const handleDownloadLink = (e) => {

		let dataLayer = window.dataLayer || [];
		dataLayer.push({
			event: ANALYTICS_CONSTANTS.EVENT,
			category: analyticsPrefix + ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.CATEGORY + ` ${pageRelatedTo}`,
			action: analyticsPrefix + ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.ACTION,
			label: analyticsPrefix + ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.LABEL
		});

		if (pageRelatedTo === 'ls3page' || pageRelatedTo === 'ls5page' || pageRelatedTo === 'ls14fbkannadapage' || pageRelatedTo === 'ls14fbtamilpage'){
			window.open(andriodLinkRef, "_self");
			FbPixelEvent_Lead();
			redirectToVideoSectionInDesktop();
		}else{
		   window.open(andriodLinkRef, "_self");
		}

		//share apk url to event api
		if (pageRelatedTo === 'ls8page' ){
		localStorage.setItem('apkURL',andriodLinkRef);
		}

		//redirectToVideoSectionInDesktop();
		extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
       
		
		if(pageRelatedTo === 'ls14page' || pageRelatedTo === 'ls14fbmalayalam' || pageRelatedTo === 'ls14fbtamilpage' || pageRelatedTo === 'ls14fbkannadapage' || pageRelatedTo === 'ls14fbbhojpuripage' || pageRelatedTo === 'ls14fbbengalipage' || pageRelatedTo === 'homepagehindifb14' || pageRelatedTo === 'ls14fbpunjabipage' || pageRelatedTo === 'ls14fboriyapage' || pageRelatedTo === 'ls14fbmarathipage' || pageRelatedTo === 'ls14fbgujaratipage'){
			FbPixelEvent_Lead();
			redirectToVideoSectionInDesktop();
		} else if (pageRelatedTo === 'ls10page'){
			// popup thank you set with true on buttonclick
			setTimeout(function () { setPopupThankyouRef(!popupThankyouRef); }, 500);

		}  else if (pageRelatedTo === "ls8page") {
			redirectToVideoSectionInDesktop();
			FbPixelEvent_Lead();
			if (fallbackCase === true) {
				window.open(process.env.DEFAULT_APK_LINK);
				console.log("fallbackCase clicked: Mobile");
			}
		} else {
			redirectToVideoSectionInDesktop();
		}

	};

	const debouncedHandleClick = useCallback(debounce((currentTime) => {

		let dataLayer = window.dataLayer || [];
		dataLayer.push({
			event: ANALYTICS_CONSTANTS.EVENT,
			category: analyticsPrefix + ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.CATEGORY + ` ${pageRelatedTo}`,
			action: analyticsPrefix + ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.ACTION,
			userBucket: props.userBucket ? props.userBucket : undefined,
			label: analyticsPrefix + ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.LABEL
		});
		handleDoubleClickCheck();

		if (!firstClickIgnored) {
			console.log('First click ignored');
			setFirstClickIgnored(true);
			setLastClickTime(currentTime);
			// redirectToVideoSectionInMobile();

		} else if (currentTime - lastClickTime <= 5000) {

			console.log('Second click detected within 5 seconds');
		} else {
			console.log('More than 5 seconds since last click');

			setFirstClickIgnored(false);
			// redirectToVideoSectionInMobile();
		}
		setLastClickTime(currentTime);

	}, 300), [firstClickIgnored, lastClickTime]);
	
	const debounceHandleClick = () => {
		const currentTime = new Date().getTime();
		debouncedHandleClick(currentTime);

		window.open(andriodLinkRef, "_self");
		extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);

  };

	return (
		<>
		{(pageRelatedTo === 'ls12page' && buttonClickedInThreshold) ?
			<MobileNumberForm />
			: ""
		}
		<div 
			className='hero-section-download-link apk-download-link'
			onClick={
				pageRelatedTo === 'ls12page' 
				? 
				(debounceHandleClick) 
				: 
				(andriodLinkRef ? handleDownloadLink : handleDownload)
			}
		>
			<div style={{ backgroundColor: props?.button?.buttonColor }} className='hero-section-download-btn-container'>
				<img src="https://static-perf1.zupee.com/wp-content/uploads/2023/06/android-icon-001.gif" alt={props.button.buttonAndroidImage.altText} width="35" height="35" className="animation-android-icon" style={{ marginRight: "20px" }} />
				{  /*<GatsbyImage
						image={props.button.buttonAndroidImage.imageFile}
						alt={props.button.buttonAndroidImage.altText}
						className='hero-section-os-image-field'
					/>*/
				}

				<p style={{ color: props?.button?.buttonText?.color }} className='hero-section-download-text'>
					{props?.button?.buttonText?.text}
				</p>
			</div>
		</div>
		</>
	);
};

export default DownloadButton;
